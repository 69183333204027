<template>
  <ClientOnly v-if="!IS_RW && labelList.length">
    <swiper-container 
      ref="behaviorSwiper" 
      class="behavior-swiper"
      init="false"
      direction="vertical"
      autoplay-disable-on-interaction="false"
      allow-touch-move="false"
      observer="true"
      observe-parents="true"
      :dir="GB_cssRight ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(item, index) in labelList"
        :key="index"
        :index="index"
      >
        <div 
          v-expose="getAnalysis(item)"
          class="cart-item-behavior"
        >
          <img
            :src="item.iconSrc"
            width="13"
            height="13"
          />
          <span class="label-text">
            {{ getItemText(item) }}
          </span>
        </div>
      </swiper-slide>
    </swiper-container>
  </ClientOnly>
</template>

<script>
import ClientOnly from 'vue-client-only'
import { isEmpty, isObject, isArray } from '@shein/common-function'
import BehaviorLabel from 'public/src/pages/cartNew/utils/behaviorLabel.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
if (typeof window !== 'undefined') {
  register()
}
daEventCenter.addSubscriber({ modulecode: '1-8-1' })
const { IS_RW, GB_cssRight } = gbCommonInfo
const SA_PREFIX = 'behavior_lable_prefix'

export default {
  name: 'CartItemBehavior',
  components: {
    ClientOnly,
  },
  props: {
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    label: {
      type: [Array, Object],
      default: () => ({})
    },
    product: {
      type: Object,
      default() {
        return {}
      }
    },
    needSwipe: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      IS_RW,
      GB_cssRight,
      exposedLabelIds: [],
    }
  },
  computed: {
    // 所有的标签
    baseLabel() {
      if (isEmpty(this.label)) return []
      // 兼容历史逻辑
      const arr = isObject(this.label) ? 
        [this.label] :
        (isArray(this.label) ? this.label : [])
      return arr.filter((v) => v.labelId)
    },
    // 排除不展示的标签
    labelList() {
      if (isEmpty(this.label)) return []
      return this.baseLabel.filter((v) => v.showLabelTag)
    },
  },
  watch: {
    labelList: {
      handler(n) {
        if (!n?.length) return
        if (typeof window != 'undefined') {
          this.initSwiper()
        }
      },
      immediate: true,
    }
  },
  beforeDestroy() {
    this.dealWithLeave()
  },
  deactivated() {
    // 处理keep-alive离开时的埋点
    this.dealWithLeave()
  },
  methods: {
    initSwiper() {
      this.$nextTick(() => {
        const options = {
          modules: this.needSwipe ? [Autoplay] : [],
          autoplay: { delay: 1500 },
          loop: true,
        }

        Object.assign(this.$refs.behaviorSwiper, options)
        this.$refs.behaviorSwiper.initialize()
        this.swiper = this.$refs.behaviorSwiper?.swiper
      })
    },

    changeAutoPlayStatus(play) {
      if (play) {
        this.swiper?.autoplay?.start?.()
      } else {
        this.swiper?.autoplay?.stop?.()
        const indexZero = this.swiper?.slides?.findIndex((v) => v.getAttribute('data-swiper-slide-index') == 0)
        this.swiper?.slideTo?.(indexZero, 0)
      }
    },
    resetSaInfo() {
      // 重置v-expose
      const daEventExpose = daEventCenter.getExposeInstance()
      daEventExpose?.resetAll?.(SA_PREFIX)

      this.exposedLabelIds = []
    },
    getItemText(item) {
      return BehaviorLabel.getText(item, this.language)
    },
    getAnalysis(item) {
      return {
        id: '1-8-1-00000000', // 假id
        prefix: SA_PREFIX, // 用于重置埋点
        callback: () => {
          if (!this.needSwipe) return
          // 30，60，90天最低价特殊处理,60,90天实验不开则展示30天的标签id
          let labelId = item.labelGear ? item.showLabel.flag ? item.labelGear[item.showLabel.flag] : item.labelGear['30'] : item.labelId
          if (!this.exposedLabelIds.includes(labelId)) {
            this.exposedLabelIds.push(labelId)
          }
        },
      }
    },
    dealWithLeave() {
      // 购物车页面在 ABT 关闭时也需要上报埋点
      const arr = this.labelList.length == 0 ? this.baseLabel : this.labelList
      if (!this.needSwipe || !arr.length) return
      daEventCenter.triggerNotice({
        daId: '1-8-1-40',
        extraData: {
          userbehavior_tips: arr.map((v) => v.labelGear ? v.showLabel.flag ? v.labelGear[v.showLabel.flag] : v.labelGear['30'] : v.labelId).join(','),
          userbehavior_tips_show: this.exposedLabelIds.join(','),
          goods_id: this.product?.goods_id,
        }
      })

      this.resetSaInfo()
    }
  },
}
</script>

<style lang="less" scoped>
.behavior-swiper {
  margin: 0;
  height: 18px;
  overflow: hidden;
}
.cart-item-behavior {
  overflow: hidden;
  font-size: 12px;
  color: @sui_color_micro_emphasis;
  display: inline-flex;
  align-items: center;
  .label-text {
    margin-left: 4/75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* rtl:begin:ignore */
    direction: ltr;
  }
}
</style>
